html {
  height: 100%;
}

body {
  min-height: 100vh;
  height: auto;
  margin: 0;
  font-family: 'Single Day', 'Roboto', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(180deg, #FAE1C5 0%, #DDBE9C 22.92%, #CA9F6E 47.4%, #F1A957 68.75%, #FAE1C5 100%) fixed;
  color: #331B05;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-family: 'Rum Raisin', sans-serif;
  font-size: xx-large !important;
}

#root {
  height: 100%;
  min-height: 100vh;
  margin: 0px;
  background: url(./img/bg-pattern.png);
  background-repeat: repeat;
  overflow: auto;
}

td {
  padding-left: 1.5em;
}

main {
  margin: 0 auto;
  width: 85%;
  text-align: center;
}
